import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/dashboard'
        },
        {
            path: '/',
            component: resolve => { require(['../components/common/Home.vue'], resolve)},
            meta: { title: '自述文件' },
            children:[
                {
                    path: '/dashboard',
                    component: resolve => { require(['../components/page/Dashboard.vue'], resolve)},
                    meta: { title: '系统首页' }
                },
                {
                    path: '/icon',
                    component: resolve => { require(['../components/page/Icon.vue'], resolve)},
                    meta: { title: '自定义图标' }
                },
                {
                    path: '/bannerTable',
                    component: resolve => { require(['../components/page/BannerTable.vue'], resolve)},
                    meta: { title: 'Banner管理' }
                },
                {
                    path: '/AdminUserTable',
                    component: resolve => { require(['../components/page/AdminUserTable.vue'], resolve)},
                    meta: { title: '用户管理' }
                },
                {
                    path: '/ConsultationTable',
                    component: resolve => { require(['../components/page/ConsultationTable.vue'], resolve)},
                    meta: { title: '咨询管理' }
                },
                {
                    path: '/ParticipantTable',
                    component: resolve => { require(['../components/page/ParticipantTable.vue'], resolve)},
                    meta: { title: '查看参与者' }
                },
                {
                    path: '/AdminRoleTable',
                    component: resolve => { require(['../components/page/AdminRoleTable.vue'], resolve)},
                    meta: { title: '角色管理' }
                },
                {
                    path: '/PrizeTable',
                    component: resolve => { require(['../components/page/PrizeTable.vue'], resolve)},
                    meta: { title: '奖品管理' }
                },
                {
                    path: '/ActivityRulesTable',
                    component: resolve => { require(['../components/page/ActivityRulesTable.vue'], resolve)},
                    meta: { title: '规则管理' }
                },
                {
                    path: '/InCodeTable',
                    component: resolve => { require(['../components/page/InCodeTable.vue'], resolve)},
                    meta: { title: '内部码管理' }
                },
                {
                    path: '/WinPrizeTable',
                    component: resolve => { require(['../components/page/WinPrizeTable.vue'], resolve)},
                    meta: { title: '中奖名单管理' }
                },
                {
                    path: '/GrantPrizeTable',
                    component: resolve => { require(['../components/page/GrantPrizeTable.vue'], resolve)},
                    meta: { title: '发放礼品管理' }
                },
                {
                    path: '/WechatUserTable',
                    component: resolve => { require(['../components/page/WechatUserTable.vue'], resolve)},
                    meta: { title: '裂变用户管理' }
                },
                {
                    path: '/StepParticipantTable',
                    component: resolve => { require(['../components/page/StepParticipantTable.vue'], resolve)},
                    meta: { title: '查看参与者' }
                },
                {
                    path: '/StepCouponTable',
                    component: resolve => { require(['../components/page/StepCouponTable.vue'], resolve)},
                    meta: { title: '领券人员名单' }
                },
                {
                    path: '/StepWaitingTable',
                    component: resolve => { require(['../components/page/StepWaitingTable.vue'], resolve)},
                    meta: { title: '等待抽奖名单' }
                },
                {
                    path: '/StepInYesTable',
                    component: resolve => { require(['../components/page/StepInYesTable.vue'], resolve)},
                    meta: { title: '内定中奖人员' }
                },
                {
                    path: '/StepPrizeTable',
                    component: resolve => { require(['../components/page/StepPrizeTable.vue'], resolve)},
                    meta: { title: '优惠券奖品' }
                },
                {
                    path: '/StepGiftTable',
                    component: resolve => { require(['../components/page/StepGiftTable.vue'], resolve)},
                    meta: { title: '抽奖奖品' }
                },
                {
                    path: '/tabs',
                    component: resolve => { require(['../components/page/Tabs.vue'], resolve)},
                    meta: { title: 'tab选项卡' }
                },
                {
                    path: '/form',
                    component: resolve => { require(['../components/page/BaseForm.vue'], resolve)},
                    meta: { title: '基本表单' }
                },
                {
                    // 富文本编辑器组件
                    path: '/editor',
                    component: resolve => { require(['../components/page/VueEditor.vue'], resolve)},
                    meta: { title: '富文本编辑器' }
                },
                {
                    // markdown组件
                    path: '/markdown',
                    component: resolve => { require(['../components/page/Markdown.vue'], resolve)},
                    meta: { title: 'markdown编辑器' }
                },
                {
                    // 图片上传组件
                    path: '/upload',
                    component: resolve => { require(['../components/page/Upload.vue'], resolve)},
                    meta: { title: '文件上传' }
                },
                {
                    // vue-schart组件
                    path: '/charts',
                    component: resolve => { require(['../components/page/BaseCharts.vue'], resolve)},
                    meta: { title: 'schart图表' }
                },
                {
                    // 拖拽列表组件
                    path: '/drag',
                    component: resolve => { require(['../components/page/DragList.vue'], resolve)},
                    meta: { title: '拖拽列表' }
                },
                {
                    // 拖拽Dialog组件
                    path: '/dialog',
                    component: resolve => { require(['../components/page/DragDialog.vue'], resolve)},
                    meta: { title: '拖拽弹框' }
                },
                {
                    // 权限页面
                    path: '/permission',
                    component: resolve => { require(['../components/page/Permission.vue'], resolve)},
                    meta: { title: '权限测试', permission: true }
                },
                {
                    path: '/404',
                    component: resolve => { require(['../components/page/404.vue'], resolve)},
                    meta: { title: '404' }
                },
                {
                    path: '/403',
                    component: resolve => { require(['../components/page/403.vue'], resolve)},
                    meta: { title: '403' }
                },
                {
                    path: '/StepRulesTable',
                    component: resolve => { require(['../components/page/StepRulesTable.vue'], resolve)},
                    meta: { title: '规则管理' }
                },
                {
                    path: '/ShopBannerTable',
                    component: resolve => { require(['../components/page/ShopBannerTable.vue'], resolve)},
                    meta: { title: '轮播图管理' }
                },
                {
                    path: '/ShopHomeMenuTable',
                    component: resolve => { require(['../components/page/ShopHomeMenuTable.vue'], resolve)},
                    meta: { title: '首页菜单管理' }
                },{
                    path: '/ShopCommodityCategoryTable',
                    component: resolve => { require(['../components/page/ShopCommodityCategoryTable.vue'], resolve)},
                    meta: { title: '商品分类' }
                },{
                    path: '/ShopCommodityTable',
                    component: resolve => { require(['../components/page/ShopCommodityTable.vue'], resolve)},
                    meta: { title: '商品列表' }
                },{
                    path: '/ShopConfigForm',
                    component: resolve => { require(['../components/page/ShopConfigForm.vue'], resolve)},
                    meta: { title: '基本配置' }
                },{
                    path: '/ShopCaseMenuTable',
                    component: resolve => { require(['../components/page/ShopCaseMenuTable.vue'], resolve)},
                    meta: { title: '日记分类' }
                },
                {
                    path: '/ShopCaseTable',
                    component: resolve => { require(['../components/page/ShopCaseTable.vue'], resolve)},
                    meta: { title: '日记列表' }
                },
                {
                    path: '/ShopUserTable',
                    component: resolve => { require(['../components/page/ShopUserTable.vue'], resolve)},
                    meta: { title: '用户管理' }
                },
                {
                    path: '/ShopOrderTable',
                    component: resolve => { require(['../components/page/ShopOrderTable.vue'], resolve)},
                    meta: { title: '订单管理' }
                },
                {
                    path: '/GroupBuyTable',
                    component: resolve => { require(['../components/page/GroupBuyTable.vue'], resolve)},
                    meta: { title: '拼团管理' }
                },
                {
                    path: '/ShopIntegralGoodsTable',
                    component: resolve => { require(['../components/page/ShopIntegralGoodsTable.vue'], resolve)},
                    meta: { title: '积分商品' }
                },
                {
                    path: '/ShopIntegralOrderTable',
                    component: resolve => { require(['../components/page/ShopIntegralOrderTable.vue'], resolve)},
                    meta: { title: '积分订单' }
                },
                {
                    path: '/ShopOnlineTable',
                    component: resolve => { require(['../components/page/ShopOnlineTable.vue'], resolve)},
                    meta: { title: '预约管理' }
                },
                {
                    path: '/ShopCoupon',
                    component: resolve => { require(['../components/page/ShopCoupon.vue'], resolve)},
                    meta: { title: '优惠券管理' }
                },
                {
                    path: '/ShopWifi',
                    component: resolve => { require(['../components/page/ShopWifi.vue'], resolve)},
                    meta: { title: 'Wifi管理' }
                },{
				    path: '/ShopAppoint',
				    component: resolve => { require(['../components/page/ShopAppoint.vue'], resolve)},
				    meta: { title: '预约管理' }
				},{
				    path: '/ShopSurvey',
				    component: resolve => { require(['../components/page/ShopSurveyTable.vue'], resolve)},
				    meta: { title: '调查问卷' }
				},{
                    path: '/ShopDoctorTable',
                    component: resolve => { require(['../components/page/ShopDoctorTable.vue'], resolve)},
                    meta: { title: '医生管理' }
                },{
                    path: '/ShopDoctorCategoryTable',
                    component: resolve => { require(['../components/page/ShopDoctorCategoryTable.vue'], resolve)},
                    meta: { title: '医生分类' }
                },{
					path:'/ShopDoctorVideoTable',
					component: resolve => { require(['../components/page/ShopDoctorVideoTable.vue'],resolve)},
					meta: { title:'视频管理' }
				},{
					path:'/ShopDoctorVideoCommentsTable',
					component: resolve => { require(['../components/page/ShopDoctorVideoCommentsTable.vue'],resolve)},
					meta: { title:'评论管理' }
				},{
                    path: '/ShopAskTable',
                    component: resolve => { require(['../components/page/ShopAskTable.vue'], resolve)},
                    meta: { title: '问答管理' }
                },{
                    path: '/ShopUserLookRecordTable',
                    component: resolve => { require(['../components/page/ShopUserLookRecordTable.vue'], resolve)},
                    meta: { title: '用户浏览记录' }
                },{
                    path: '/ShopConsultationTable',
                    component: resolve => { require(['../components/page/ShopConsultationTable.vue'], resolve)},
                    meta: { title: '咨询管理' }
                },{
                    path: '/ShopBargainOrderTable',
                    component: resolve => { require(['../components/page/ShopBargainOrderTable.vue'], resolve)},
                    meta: { title: '砍价订单管理' }
                },{
                    path: '/ShopConsultCationCardTable',
                    component: resolve => { require(['../components/page/ShopConsultCationCardTable.vue'], resolve)},
                    meta: { title: '咨询名片管理' }
                },{
                    path: '/ZpConfigForm',
                    component: resolve => { require(['../components/page/ZpConfigForm.vue'], resolve)},
                    meta: { title: '转盘抽奖配置' }
                },{
                    path: '/ZpPrizeTable',
                    component: resolve => { require(['../components/page/ZpPrizeTable.vue'], resolve)},
                    meta: { title: '奖品管理' }
                },{
                    path: '/ZpPrizeRecordTable',
                    component: resolve => { require(['../components/page/ZpPrizeRecordTable.vue'], resolve)},
                    meta: { title: '中奖记录管理' }
                },{
                    path: '/ZpInvitationCodeTable',
                    component: resolve => { require(['../components/page/ZpInvitationCodeTable.vue'], resolve)},
                    meta: { title: '邀请码管理' }
                },{
                    path: '/FlopConfigForm',
                    component: resolve => { require(['../components/page/FlopConfigForm.vue'], resolve)},
                    meta: { title: '美丽翻牌配置' }
                },{
                    path: '/FlopPrizeTable',
                    component: resolve => { require(['../components/page/FlopPrizeTable.vue'], resolve)},
                    meta: { title: '翻牌奖品管理' }
                },{
                    path: '/FlopPrizeRecordTable',
                    component: resolve => { require(['../components/page/FlopPrizeRecordTable.vue'], resolve)},
                    meta: { title: '翻牌记录管理' }
                },{
                    path: '/NoseConfigForm',
                    component: resolve => { require(['../components/page/NoseConfigForm.vue'], resolve)},
                    meta: { title: '猜鼻子配置' }
                },{
                    path: '/NoseSubjectTable',
                    component: resolve => { require(['../components/page/NoseSubjectTable.vue'], resolve)},
                    meta: { title: '题目管理' }
                },{
                    path: '/NosePrizeTable',
                    component: resolve => { require(['../components/page/NosePrizeTable.vue'], resolve)},
                    meta: { title: '奖品管理' }
                },{
                    path: '/NosePrizeRecordTable',
                    component: resolve => { require(['../components/page/NosePrizeRecordTable.vue'], resolve)},
                    meta: { title: '猜鼻子记录管理' }
                },{
                    path: '/MouseMoneyConfigForm',
                    component: resolve => { require(['../components/page/MouseMoneyConfigForm.vue'], resolve)},
                    meta: { title: '鼠钱基本配置' }
                },{
                    path: '/MouseMoneyPrizeTable',
                    component: resolve => { require(['../components/page/MouseMoneyPrizeTable.vue'], resolve)},
                    meta: { title: '鼠钱奖品管理' }
                },{
                    path: '/MouseMoneyPriceTable',
                    component: resolve => { require(['../components/page/MouseMoneyPriceTable.vue'], resolve)},
                    meta: { title: '鼠钱纸币管理' }
                },{
                    path: '/MouseMoneyInCodeTable',
                    component: resolve => { require(['../components/page/MouseMoneyInCodeTable.vue'], resolve)},
                    meta: { title: '鼠钱内部码管理' }
                },{
                    path: '/MouseMoneyPrizeRecordTable',
                    component: resolve => { require(['../components/page/MouseMoneyPrizeRecordTable.vue'], resolve)},
                    meta: { title: '中奖记录管理' }
                },{
                    path: '/MouseMoneyConsultationTable',
                    component: resolve => { require(['../components/page/MouseMoneyConsultationTable.vue'], resolve)},
                    meta: { title: '咨询管理' }
                },{
                    path: '/ShopConsultationGoodsTable',
                    component: resolve => { require(['../components/page/ShopConsultationGoodsTable.vue'], resolve)},
                    meta: { title: '咨询商品二维码' }
                },{
                    path: '/InsideQusTable',
                    component: resolve => { require(['../components/page/InsideQusTable.vue'], resolve)},
                    meta: { title: '问卷管理' }
                },{
                    path: '/InsideAnswerTable',
                    component: resolve => { require(['../components/page/InsideAnswerTable.vue'], resolve)},
                    meta: { title: '答题管理' }
                },{
                    path: '/ShopPosterTable',
                    component: resolve => { require(['../components/page/ShopPosterTable.vue'], resolve)},
                    meta: { title: '海报管理' }
                },{
                    path: '/ShopNewsTable',
                    component: resolve => { require(['../components/page/ShopNewsTable.vue'], resolve)},
                    meta: { title: '退款通知' }
                },{
                    path: '/ShopRecordTable',
                    component: resolve => { require(['../components/page/ShopRecordTable.vue'], resolve)},
                    meta: { title: '操作日志' }
                },{
                    path: '/FissionConfigForm',
                    component: resolve => { require(['../components/page/FissionConfigForm.vue'], resolve)},
                    meta: { title: '基本配置' }
                },{
                    path: '/FissionPrizeTable',
                    component: resolve => { require(['../components/page/FissionPrizeTable.vue'], resolve)},
                    meta: { title: '奖品管理' }
                },{
                    path: '/FissionBannerTable',
                    component: resolve => { require(['../components/page/FissionBannerTable.vue'], resolve)},
                    meta: { title: 'Banner管理' }
                },{
                    path: '/FissionShareTable',
                    component: resolve => { require(['../components/page/FissionShareTable.vue'], resolve)},
                    meta: { title: '客户管理' }
                },{
                    path: '/FissionGoodsTable',
                    component: resolve => { require(['../components/page/FissionGoodsTable.vue'], resolve)},
                    meta: { title: '主推商品' }
                },{
                    path: '/FissionPrizeRecordTable',
                    component: resolve => { require(['../components/page/FissionPrizeRecordTable.vue'], resolve)},
                    meta: { title: '兑换记录' }
                },{
                    path: '/FortuneBagConfigForm',
                    component: resolve => { require(['../components/page/FortuneBagConfigForm.vue'], resolve)},
                    meta: { title: '基本配置' }
                },{
                    path: '/FortuneBagGoodsTable',
                    component: resolve => { require(['../components/page/FortuneBagGoodsTable.vue'], resolve)},
                    meta: { title: '可选商品' }
                },{
                    path: '/ActivityConfigTable',
                    component: resolve => { require(['../components/page/ActivityConfigTable.vue'], resolve)},
                    meta: { title: '活动配置' }
                },{
                    path: '/ActivityGoodsTable',
                    component: resolve => { require(['../components/page/ActivityGoodsTable.vue'], resolve)},
                    meta: { title: '商品管理' }
                },{
                    path: '/ActivityConsultationGoodsTabe',
                    component: resolve => { require(['../components/page/ActivityConsultationGoodsTabe.vue'], resolve)},
                    meta: { title: '咨询二维码' }
                },{
                    path: '/HbyConfigForm',
                    component: resolve => { require(['../components/page/HbyConfigForm.vue'], resolve)},
                    meta: { title: '基本配置' }
                },{
                    path: '/HbyUserTable',
                    component: resolve => { require(['../components/page/HbyUserTable.vue'], resolve)},
                    meta: { title: '用户管理' }
                },{
                    path: '/HbyRecordTable',
                    component: resolve => { require(['../components/page/HbyRecordTable.vue'], resolve)},
                    meta: { title: '中奖记录管理' }
                },{
                    path: '/HbyPrizeTable',
                    component: resolve => { require(['../components/page/HbyPrizeTable.vue'], resolve)},
                    meta: { title: '奖品管理' }
                },{
                    path: '/GroupBooking',
                    component: resolve => { require(['../components/prom/GroupBooking.vue'], resolve)},
                    meta: { title: '拼团' }
                },{
                    path: '/Ad',
                    component: resolve => { require(['../components/prom/Ad.vue'], resolve)},
                    meta: { title: '广告列表' }
                },{
                    path: '/AdPosition',
                    component: resolve => { require(['../components/prom/AdPosition.vue'], resolve)},
                    meta: { title: '广告位' }
                },{
                    path: '/ShopFormTable',
                    component: resolve => { require(['../components/page/ShopFormTable.vue'], resolve)},
                    meta: { title: '老带新有礼' }
                },{
                    path: '/ShopOperationTable',
                    component: resolve => { require(['../components/page/ShopOperationTable.vue'], resolve)},
                    meta: { title: '术后注意事项' }
                },{
                    path: '/ShopSatisfiedTable',
                    component: resolve => { require(['../components/page/ShopSatisfiedTable.vue'], resolve)},
                    meta: { title: '满意度调查' }
                },{
                    path: '/ShopVideoCategoryTable',
                    component: resolve => { require(['../components/page/ShopVideoCategoryTable.vue'], resolve)},
                    meta: { title: '视频分类' }
                },{
                    path: '/ShopDeanTabe',
                    component: resolve => { require(['../components/page/ShopDeanTabe.vue'], resolve)},
                    meta: { title: '翻牌' }
                },{
                    path: '/ShopJumpTabe',
                    component: resolve => { require(['../components/page/ShopJumpTabe.vue'], resolve)},
                    meta: { title: '跳一跳' }
                },{
                    path: '/ShopCrownTabe',
                    component: resolve => { require(['../components/page/ShopCrownTabe.vue'], resolve)},
                    meta: { title: '抢夺王冠' }
                },{
                    path: '/ShopBoxTabe',
                    component: resolve => { require(['../components/page/ShopBoxTabe.vue'], resolve)},
                    meta: { title: '盲盒' }
                },{
                    path: '/ShopHospitalVideoTable',
                    component: resolve => { require(['../components/page/ShopHospitalVideoTable.vue'], resolve)},
                    meta: { title: '内容管理' }
                },{
                    path: '/ShopHospitalCommentsTable',
                    component: resolve => { require(['../components/page/ShopHospitalCommentsTable.vue'], resolve)},
                    meta: { title: '内容评论' }
                },{
                    path: '/ShopHospitalCategoryTable',
                    component: resolve => { require(['../components/page/ShopHospitalCategoryTable.vue'], resolve)},
                    meta: { title: '分类管理' }
                },{
                    path: '/ShopStarStarTabe',
                    component: resolve => { require(['../components/page/ShopStarStarTabe.vue'], resolve)},
                    meta: { title: '天降星星' }
                },{
                    path: '/ShopFloorTabe',
                    component: resolve => { require(['../components/page/ShopFloorTabe.vue'], resolve)},
                    meta: { title: '盖楼' }
                },{
                    path: '/ShopCakeTabe',
                    component: resolve => { require(['../components/page/ShopCakeTabe.vue'], resolve)},
                    meta: { title: '叠蛋糕' }
                },{
                    path: '/ShopChophandTabe',
                    component: resolve => { require(['../components/page/ShopChophandTabe.vue'], resolve)},
                    meta: { title: '剁手王' }
                },{
                    path: '/ShopTreeTabe',
                    component: resolve => { require(['../components/page/ShopTreeTabe.vue'], resolve)},
                    meta: { title: '星愿树' }
                },{
                    path: '/ShopCapsuleTabe',
                    component: resolve => { require(['../components/page/ShopCapsuleTabe.vue'], resolve)},
                    meta: { title: '扭蛋胶囊' }
                },{
                    path: '/ShopActivityMianTable',
                    component: resolve => { require(['../components/page/ShopActivityMianTable.vue'], resolve)},
                    meta: { title: '面膜活动' }
                },{
                    path: '/ShopActivityMianCodeTable',
                    component: resolve => { require(['../components/page/ShopActivityMianCodeTable.vue'], resolve)},
                    meta: { title: '咨询二维码' }
                },{
                    path: '/ShopActivityMConfigTable',
                    component: resolve => { require(['../components/page/ShopActivityMConfigTable.vue'], resolve)},
                    meta: { title: '活动配置' }
                },{
                    path: '/ShopNewDoctorTable',
                    component: resolve => { require(['../components/page/ShopNewDoctorTable.vue'], resolve)},
                    meta: { title: '医生管理' }
                },{
                    path: '/ShopNewDoctorCategoryTable',
                    component: resolve => { require(['../components/page/ShopNewDoctorCategoryTable.vue'], resolve)},
                    meta: { title: '医生分类' }
                },{
                    path: '/ShopCasesTable',
                    component: resolve => { require(['../components/page/ShopCasesTable.vue'], resolve)},
                    meta: { title: '医生案例' }
                },{
                    path: '/ShopCasesCategoryTable',
                    component: resolve => { require(['../components/page/ShopCasesCategoryTable.vue'], resolve)},
                    meta: { title: '案例分类' }
                },{
                    path: '/ShopConsultationDoctorTable',
                    component: resolve => { require(['../components/page/ShopConsultationDoctorTable.vue'], resolve)},
                    meta: { title: '医生咨询二维码' }
                }

            ]
        },
        {
            path: '/login',
            component: resolve => { require(['../components/page/Login.vue'], resolve)}
        },
        {
            path: '*',
            component: resolve => { require(['../components/page/404.vue'], resolve)}
        }
    ]
})
