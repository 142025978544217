// 配置API接口地址
//var root = 'http://shopxcx.com/index.php/Admin';  //接口访问地址
//var uploadUrl = 'http://shopxcx.com/index.php/Common';  //上传文件访问地址
//var editorUrl = 'http://shopxcx.com/ueditor/php/controller.php';  //富文本编辑器控制器地址

 var root = 'https://xaxcx.beyestar.com/index.php/Admin';  //接口访问地址
 var uploadUrl = 'https://xaxcx.beyestar.com/index.php/Common';  //上传文件访问地址
 var editorUrl = 'https://xaxcx.beyestar.com/ueditor/php/controller.php';  //富文本编辑器控制器地址
// 引用axios
var axios = require('axios');
// 自定义判断元素类型JS
function toType (obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
// function filterNull (o) {
//     for (var key in o) {
//         if (o[key] === null) {
//             delete o[key]
//         }
//         if (toType(o[key]) === 'string') {
//             o[key] = o[key].trim()
//         } else if (toType(o[key]) === 'object') {
//             o[key] = filterNull(o[key])
//         } else if (toType(o[key]) === 'array') {

//             o[key] = filterNull(o[key])
//         }
//     }
//     return o
// }
/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/

function apiAxios (method, url, params, success, failure) {
    // if (params) {
    //     params = filterNull(params)
    // }
    axios({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        baseURL: root,
        withCredentials: true  //允许携带cookie
    })
        .then(function (res) {
            if (res.data.success === true) {
                //此时登陆失效
                if(res.data.data === false){
                    // alert(res.data.msg);
                    window.location.href="/#/login";
                }
                if (success) {
                    success(res.data)
                }
            } else {
                if (failure) {
                    failure(res.data)
                } else {
                    console.log(res.data);
                    window.alert(res.data.msg)
                }
            }
        })
        .catch(function (err) {
            let res = err.response
            if (err) {
                console.log(err);
                window.alert('api error, HTTP CODE: ' + res.status)
            }
        })
}

// 返回在vue模板中的调用接口
export default {
    get: function (url, params, success, failure) {
        return apiAxios('GET', url, params, success, failure)
    },
    post: function (url, params, success, failure) {
        return apiAxios('POST', url, params, success, failure)
    },
    put: function (url, params, success, failure) {
        return apiAxios('PUT', url, params, success, failure)
    },
    delete: function (url, params, success, failure) {
        return apiAxios('DELETE', url, params, success, failure)
    },
    url: root,
    uploadUrl:uploadUrl,
    editorUrl: editorUrl
}
